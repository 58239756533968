import SaController from './sa_controller';
import { get } from '@rails/request.js'

export default class extends SaController {
  static values = { url: String, clicks: Number, selected: Boolean, highlight: Boolean };
  static targets = ['editArea', 'form', 'focus', 'ignore', 'submitter'];

  connect() {
    this.element.addEventListener("click", this.loadEditor);
    this.element.addEventListener("click", this.selected);
  }

  disconnect() {
    this.element.removeEventListener("click", this.loadEditor);
    this.element.removeEventListener("click", this.selected);
    window.removeEventListener("click", this.checkOutsideClick);
  }

  formTargetConnected = () => {
    this.element.classList.add('editing');
    window.addEventListener("cell-edit:closeother", this.closeOther);
    this.element.removeEventListener("click", this.loadEditor);
  }

  formTargetDisconnected = () => {
    this.element.classList.remove('editing');
    window.removeEventListener("cell-edit:closeother", this.closeOther);
    this.element.addEventListener("click", this.loadEditor);
  }

  focusTargetConnected = () => {
    this.focusTarget.focus();
  }

  highlightValueChanged = () => {
    if (this.highlightValue) {
      this.element.classList.add('ring-1', 'ring-inset');
    } else {
      this.element.classList.remove('ring-1', 'ring-inset');
    }
  }

  get canEdit() {
    return !this.table.isSelecting || this.element.closest('tr').classList.contains('selected')
  }

  loadEditor = (event) => {
    if (!this.canEdit) {
      return;
    } else {
      event.preventDefault();
      event.stopPropagation();
      // CustomEvent to close other editors
      if(!this.urlValue) { return }
      get(this.urlValue, {responseKind: 'turbo-stream'})
    }
    const closeEditorEvent = new CustomEvent("cell-edit:closeother", {detail: {id: this.element.id}});
    window.dispatchEvent(closeEditorEvent);
    window.addEventListener("click", this.checkOutsideClick);
  }

  selected = () => {
    this.table.highlight(this);
  }

  get table() {
    return this.element.closest('table').selectableTableController;
  }

  closeOther = (event) => {
    if (event.detail.id != this.element.id) {
      this.cancel();
    }
  }

  submit = () => {
    if (this.table.isSelecting) {
      this.table.submit(this.formTarget);
    } else {
      this.formTarget.requestSubmit(this.submitterTarget);
    }
  }

  cancel(event) {
    event?.preventDefault();
    event?.stopPropagation();
    this.editAreaTarget.remove();
  }

  checkOutsideClick = (event) => {
    if (this.element.contains(event.target)) {
      return;
    } else {
      // Any trigger element must have a controller with a reset method...not ideal
      if (this.hasTriggerTarget && this.triggerTarget.controller !== undefined) {
        this.triggerTarget.controller.reset();
      }
      this.cancel();
    }
  }
}
