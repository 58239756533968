import SaController from '../sa_controller';

export default class extends SaController {
  static targets = [ 'content', 'message' ]

  connect() {
  }

  copy() {
    navigator.clipboard.writeText(this.contentTarget.value);
    this.addMessage();
    this.removeMessage();
  }

  addMessage() {
    let div = document.createElement('div');
    let msg = document.createElement('p');
    msg.innerHTML = 'URL copied to clipboard';
    div.append(msg);
    this.messageTarget.classList.remove('hidden');
    this.messageTarget.append(div);
  }

  removeMessage() {
    setTimeout(() => {
      this.messageTarget.innerHTML = '';
      this.messageTarget.classList.add('hidden');
    }, 3000);
    
  }
  
}
