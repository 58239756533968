import SaController from './sa_controller';

export default class extends SaController {
  static values = { query: String };

  connect() {
  }

  on() {
    this.element.append(this.matchingElement.children[0]);
  }

  off() {
    this.matchingElement.append(this.element.children[0]);
  }

  addMutationObserver() {
    const observer = new MutationObserver((mutations) => {
      this.element.innerHTML = this.matchingElement.outerHTML;
    })
    observer.observe(this.matchingElement, { childList: true, subtree: true });
  }

  get matchingElement() {
    return document.querySelector(this.queryValue);
  }

  disconnect() {
  }
}
