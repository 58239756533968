import SaController from '../sa_controller';
import {computePosition, offset, flip} from '@floating-ui/dom';

export default class extends SaController {
  static targets = ['clear', 'checkbox']

  connect() {
    //console.log("DrawerController connected");
  }

  disconnect() {
  }

  toggleDrawer(event) {
    const id = event.params.id;
    const checkbox = this.element.querySelector(`#${id}`);
    checkbox.checked = !checkbox
  }

  showDrawer(event) {
    // Close all other drawers
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false;
    })

    const id = event.params.id;
    const checkbox = this.element.querySelector(`#${id}`);
    checkbox.checked = true;

    // Clear any targets we have set
    this.clearTargets.forEach((clear) => {
      clear.innerHTML = "Loading...";
    });
  }
  
  hideDrawer(event) {
    const id = event.params.id;
    const checkbox = this.element.querySelector(`#${id}`);
    checkbox.checked = false;
  }
}
