import SectionController from './section_controller';

/* There's not a good reason this isn't a web component outside of
 * stimulus being the main technology we use for other things */
export default class extends SectionController {
  static targets = ['image', 'progress', 'checkbox', 'inner', 'radio']

  static values = {
    asset: Object,
    progress: Number,
    selected: Boolean,
    checked: Boolean,
    checkable: Boolean,
    lazy: Boolean
  }

  connect() {
    this.buildShell();
    this.element.uploadAssetController = this;
    this.element.assetController = this;
    this.attachListeners();

    if(this.lazyValue) {
      this.createObserver();
    } else {
      this.visible();
    }
  }

  state() {
    return this.assetValue;
  }

  visible = () => {
    this.buildBody();
  }

  disconnect() {
    this.removeListeners();
  }

  createObserver() {
    this.observer = new IntersectionObserver(
      entries => this.handleIntersect(entries)
    )
    this.observer.observe(this.element);
  }

  handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.observer.unobserve(this.element);
        this.visible();
      } 
    })
  }

  checkedValueChanged() {
    if(this.hasCheckboxTarget) {
      this.checkboxTarget.checked = this.checkedValue;
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    console.log(`Attribute ${name} has changed.`);
  }

  setState() {
    // Ensure the everything is set based on values
    // after we build body
    this.checkedValueChanged()
  }

  checkSelected = (event) => {
    if(event.detail.assets.includes(this.assetValue.id)) {
      this.checkedValue = true;
    } else {
      this.checkedValue = false;
    }
  }

  attachListeners() {
    this.listenOnManager('upload_progress', this.onProgress);
    this.listenOnManager('upload_success', this.onSuccess);
    this.listenOnManager('upload_fail', this.onFail);
    this.listenOnManager('assets_changed', this.checkSelected);
    this.element.addEventListener('visible', this.visible);
  }

  removeListeners() {
    this.listenOffManager('upload_progress', this.onProgress);
    this.listenOffManager('upload_success', this.onSuccess);
    this.listenOffManager('upload_fail', this.onFail);
    this.listenOffManager('assets_changed', this.checkSelected);
    if(this.element) {
      this.element.removeEventListener('visible', this.visible);
    }
  }

  onProgress = (event) => {
    if (event.detail.asset.id == this.assetValue.id) {
      this.progressValue = event.detail.progress*100;
    }
  }

  onSuccess = (event) => {
    if (event.detail.asset.id == this.assetValue.id) {
      this.progressTarget.classList.add('hidden');
      this.assetValue = Object.assign(this.assetValue, {initializing: false});
      this.buildBody();
    }
  }

  onFail = (event) => {
    if (event.detail.asset.id == this.assetValue.id) {
      this.element.classList.remove('uploading');
      this.element.classList.add('failed');
    }
  }

  progressValueChanged(value) {
    if(this.hasProgressTarget) {
      this.progressTarget.setAttribute('progress', value);
    }
  }

  on() {
    console.log("on");
    //this.checkboxTarget.checked = true;
    this.checkedValue = true;
  }

  off() {
    console.log("off");
    //this.checkboxTarget.checked = false;
    this.checkedValue = false;
  }

  assetPicked() {
    console.log("ASSET PICKED");
    this.on();
    this.dispatchAssetUpdate();
  }

  assetRemoved() {
    this.off();
    this.dispatchAssetUpdate();
  }

  assetToggle() {
    if(this.checkedValue) {
      this.assetRemoved();
    } else {
      this.assetPicked();
    }
  }

  dispatchAssetUpdate() {
    console.log("DISPATCHING ASSET UPDATE")
    const evt = new CustomEvent('assetUpdate', {
      detail: {
        assetId: this.assetValue.id,
        selected: this.checkedValue
      }
    });
    this.element.dispatchEvent(evt);
  }

  dispatchAssetSelected = () => {
    console.log("DISPATCHING ASSET SELECTED")
    const evt = new CustomEvent('assetSelected', {
      detail: {
        assetId: this.assetValue.id
      }
    });

    this.element.dispatchEvent(evt);
  }

  dispatchAssetDeselected = () => {
    console.log("DISPATCHING ASSET DESELECTED")
    const evt = new CustomEvent('assetSelected', {
      detail: {
        assetId: null
      }
    });

    this.element.dispatchEvent(evt);
  }

  select() {
    this.radioTarget.checked = !this.radioTarget.checked;

    if(this.radioTarget.checked) {
      this.dispatchAssetSelected();
    } else {
      this.dispatchAssetDeselected();
    }
  }

  buildShell() {
    this.element.innerHTML = `
      <div class="group relative aspect-square bg-gray-200 rounded">
      </div>
    `
  }

  header() {
    if(this.checkableValue) {
      return `
<div class="absolute top-0 left-0 right-0 z-[11] h-8 bg-gray-200 bg-opacity-50 overflow-hidden">
  <div class="ml-2 mr-12 overflow-hidden">${this.assetValue.name}</div>
</div>
<div>
  <input type="checkbox" class="peer w-0 h-0" data-compose--option-asset-target="checkbox">
  <div class="block peer-checked:hidden bg-gray-200  tranisition-all absolute top-1 right-1 w-6 h-6 rounded-sm border z-[13] border-gray-800" data-action="click->compose--option-asset#assetPicked:stop"></div>

  <div class="peer-checked:block opacity-0 peer-checked:opacity-100 duration-200 transition-all absolute top-1 right-1 w-6 h-6 rounded-sm peer-checked:bg-brand-default z-[12]" data-action="click->compose--option-asset#assetRemoved:stop">
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" class="w-6 h-6 fill-current w-4 h-4 text-white"><path d="M0 0h24v24H0z" fill="none"></path><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path></svg>
  </div>
</div>`
    } else {
      return `
<div class="absolute top-0 left-0 right-0 z-[11] h-8 bg-gray-200 bg-opacity-50 overflow-hidden">
  <div class="ml-2 mr-12 overflow-hidden">${this.assetValue.name}</div>
  <div class="absolute top-0 right-0 cursor-pointer" 
       data-action="click->compose--media#removeMedia:stop"
       data-compose--media-asset-id-param="${this.assetValue.id}"
  > 
    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" width="24px" fill="#333"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z"/></svg>
  </div>
</div>`
    }
  }

  media() {
    if(this.assetValue.media_type === 'image') {
      return `<img data-compose--option-asset-target="image" data-compose--manager-target="previewable" data-proxy-src="${this.assetValue.proxy_url}" data-src="${this.assetValue.url}" class="w-full h-full object-cover overflow-hidden rounded" loading="lazy">`
    } else if(this.assetValue.media_type === 'video' && this.assetValue.initializing) {
      //return `<img data-compose--option-asset-target="image" data-compose--manager-target="previewable" data-proxy-src="${this.assetValue.poster_url}" data-src="${this.assetValue.url}" class="w-full h-full object-cover overflow-hidden rounded" loading="lazy">`
      return `<div class="bg-gray-200 w-full h-full"></div>
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" size="12" class="w-12 h-12 fill-current shadow-xl absolute inset-center text-white"><path d="M0 0h24v24H0z" fill="none"></path><path d="M8 5v14l11-7z"></path></svg>`

    } else {
      return `<img data-compose--option-asset-target="image" src="${this.assetValue.poster_url}" class="w-full h-full object-cover overflow-hidden rounded" loading="lazy">
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" size="12" class="w-12 h-12 fill-current absolute inset-center text-white"><path d="M0 0h24v24H0z" fill="none"></path><path d="M8 5v14l11-7z"></path></svg>`
    }
  }

  buildBody() {
    this.element.innerHTML = `<div data-compose--option-asset-target="inner" class="group relative w-full h-full" data-action="click->compose--option-asset#select">
      ${this.header()}
      <input type="radio" name="selected_asset" class="peer w-0 h-0" data-compose--option-asset-target="radio">
      <div class="absolute inset-0 rounded cursor-pointer ring-brand-default ring-inset-2 peer-checked:ring-2 hover:ring-2 hover:ring-gray-400 peer-checked:ring-brand-default">
        <progress-ring data-compose--option-asset-target="progress" stroke="5" radius="30" progress="0" data-uploader-target="progress" class="absolute inset-center"></progress-ring>
        ${this.media()}
      </div>
    </div>`

    this.setState();
  }

  removeBody() {
    this.element.innerHTML = '';
  }
}
