import ComposeController from '../compose_controller';
import { put, post } from '@rails/request.js';
import { UIEvent, PhotoEditorSDKUI, ImageFormat, ExportFormat } from 'photoeditorsdk';
import { debounce, some } from 'lodash';
import ImageEditor from './image_editor';
import MediaInfo from 'mediainfo.js';
import Queue from './queue';
import 'swiped-events';

export default class extends ComposeController {
  static targets = [
    'fileInput', 
    'noteInput', 
    'footer', 
    'actionSelect', 
    'tabLeft', 
    'panelLeft',
    'tabRight', 
    'panelRight',
    'section',
    'file',
    'reveal',
    'revealFrame',
    'droparea',
    'dropzone',
    'assignedToId',
    'preview',
    'previewTab',
    'asset',
    'previewable',
    'convertable',
    'progressable',
    'main'
  ];

  static values = {
    id: String,
    activityId: String,
    basePath: String,
    social: Boolean
  }

  connect() {
    this.previews = {};
    this.previewsByUrl = {};
    this.processes = [];
    this.selectedAction = null;
    this.element.composeManager = this;

    //this.showTaskTab();

    this.element.addEventListener('swiped-left', () => {
      this.showPreviewTab();
    });


    this.element.addEventListener('swiped-right', () => {
      this.hidePreviewTab();
    });

    MediaInfo({ format: 'JSON', locateFile: () => 'https://assets.socialassurance.com/mediainfo/MediaInfoModule.wasm' }, (mediainfo) => {
      this.mediainfo = mediainfo;
    });

    ImageEditor.initialize();
    this.handleSuccessfulSubmit();
  }

  previewableTargetConnected(img) {
    const file = this.getPreviewByUrl(img.dataset.src)
    if(file) {
      const url = URL.createObjectURL(file);
      img.src = url;
    } else {
      img.src = img.dataset.proxySrc;
    }
  }
  
  toggleFullScreen = () => {
    this.element.closest(".modal").classList.toggle("small-modal")
    this.element.closest(".modal").classList.add("modal-preview")
  }

  toggleTaskTab = () => {
    this.element.closest(".modal").classList.toggle("modal-task")
  }
  showTaskTab = () => {
    this.element.closest(".modal").classList.add("modal-task")
  }

  hideTaskTab = () => {
    this.element.closest(".modal").classList.remove("modal-task")
  }

  togglePreviewTab = () => {
    this.element.closest(".modal").classList.toggle("modal-preview")
  }
  showPreviewTab = () => {
    this.element.closest(".modal").classList.add("modal-preview")
  }

  hidePreviewTab = () => {
    this.element.closest(".modal").classList.remove("modal-preview")
  }

  showPreviewTab = () => {
    this.element.closest(".modal").classList.add("modal-preview")
  }

  hidePreviewTab = () => {
    this.element.closest(".modal").classList.remove("modal-preview")
  }

  dropareaTargetConnected(element) {
    element.addEventListener('dragover', (evt) => { 
      if(evt.dataTransfer.types[0] != 'Files') return;

      evt.preventDefault();
      this.dropzoneTarget.classList.remove('hidden')
      this.hideDropZone();
    });
  }

  hideDropZone = debounce(() => {
    this.dropzoneTarget.classList.add('hidden')
  }, 400);

  dropzoneTargetConnected(element) {
    element.addEventListener('drop', this.drop);
  }

  actionSelectTargetConnected(element) {
    element.addEventListener('change', this.updateAction); 
  }

  sectionTarget(element) {
    console.log("SECTION ADDED");
  }

  reveal(evt) {
    const url = evt.params.url;
    this.revealTarget.classList.remove("hidden");
    this.updateReveal(url);
  }

  updateReveal(url) {
    this.revealFrameTarget.src = url;
  }
  
  hideReveal(evt) {
    this.revealTarget.classList.add("hidden");
    setTimeout(() => { this.revealFrameTarget.innerHTML = ""; });
  }

  fileTypeCheck(file) {
    if(this.socialValue) {
      return file.type.startsWith('image') || file.type.startsWith('video')
    } else  {
      return true;
    }
  }

  drop = async(evt) => {
    evt.preventDefault();

    if (evt.dataTransfer.items) {
      [...evt.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          if(this.fileTypeCheck(item)) {
            const file = item.getAsFile();

            this.composeManager.requestAsset(file, asset => this.takeAction(
              'media',
              'attach_asset', 
              { asset_id: asset.id },
            ))
          } else {
            alert("Only images and videos are supported on social media posts");
          }
        }
      });
    } 
  }

  async waitForProcesses() {
    await Promise.all(this.processes);
    this.processes = [];
  }

  dim() {
    this.element.closest(".modal").classList.add("dimmed")
  }

  unDim() {
    this.element.closest(".modal")?.classList?.remove("dimmed")
  }

  async sideSubmitPause(action, data) {
    this.dim();
    this.sideSubmit(action, data)
  }

  async sideSubmit(action, data) {
    // Single File
    Queue.enqueue(()=> {
      const promise = put(action, { body: data, responseKind: "turbo-stream" })
      promise.then(resp => { 
        this.unDim();
        this.handleSuccessfulSubmit();
      }).catch(errors => { 
        this.unDim();
        if(errors) {
          if(confirm("Something went wrong, reload from last edit?")) {
            Turbo.visit(this.basePathValue + "/edit");
          }
        }
      });
      return promise;
    });
  }

  reloadPreview() {
    this.previewTargets.forEach(elem => { elem.removeAttribute('complete'); elem.reload() })
  }

  handleSuccessfulSubmit = debounce(() => {
    if(this.hasPreviewTarget) {
      // Reload preview
      if(this.state.social_channels?.channels) {
        this.previewTabTarget.classList.remove('hidden')
        setTimeout(() => {
          this.reloadPreview();
        })
      } else {
        this.previewTabTarget.classList.add('hidden')
      }
    }
  }, 200);

  updateAction = async () => {
    const response = await put(this.basePathValue, { 
      body: {
        take_action: 'update_action', 
        key: 'composable',
        set_as: this.actionSelectTarget.value
      }, responseKind: "turbo-stream" })
  }

  update(evt) {

  }

  imageDimensions = (url) => new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width
      })
    }
    img.src = url;
  })

  get uploadingAssets() {
    return some(this.element.querySelectorAll('[data-controller=compose--asset-alt]'), elem => elem.assetController.uploading);
  }

  get state() {
    let _state = {};
    this.sectionTargets.forEach((section) => {
      const controller = section.sectionController;
      _state[controller.key] = controller.state;
    })
    return _state;
  }

  /*
   * Is there outstanding text in the note area
   */
  get unsavedNote() { 
    if(this.hasNoteInputTarget) {
      // Has value and note hidden
      return this.noteInputTarget.value.length > 0 && this.noteInputTarget.offsetWidth > 0 && this.noteInputTarget.offsetHeight > 0;
    }
  }

  get assignedToId() {
    return this.hasAssignedToIdTarget ? this.assignedToIdTarget.value : '';
  }

  get frameToRefreshOnSuccess() {
    const targetedFrame = this.element.closest("#compose-frame").dataset.frame;
    // Specific frame set on compose modal turbo-frame
    if(targetedFrame) {
      return document.querySelector(`#{targetedFrame}`);
    } else {
      // Look for a frame on the page that says it's a compose-target
      return document.querySelector("turbo-frame[compose-target]")
    }
  }

  commitAction(evt) {
    return evt.params.action || this.actionSelectTarget.value;
  }

  okToCommit() {
    if(this.processing) {
      alert("Some updates are still processing, please wait");
      return false;
    } else if(this.uploadingAssets) {
      alert("Please wait for files to finish uploading");
      return false;
    } else if(this.unsavedNote) {
      return confirm("You have an unsaved note, do you want to continue?");
    }
    return true;
  }

  /*
   * Make request to server to publish composable changes to
   * associated ChannelActivity. 
   *
   * Sends a complete copy of each sections state
   */
  async commitChanges(evt) {
    if(!this.okToCommit()) return;

    // Disable commit button
    evt.target.classList.add("pointer-events-none", "btn-disabled")

    const action = this.commitAction(evt);
    // Setup any changes required for committing on controllers
    this.sectionTargets.forEach((section) => { section.sectionController.committing() });
    

    fetch(`${this.basePathValue}/commit`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        'state': action, assigned_to_id: this.assignedToId, content: this.state
      })
    }).then(async(response) => {
      if(response.status == 200) {
        this.commitSuccess();
      } else {
        // Something went wrong, render the turbo-stream error/content
        const html = await response.text();
        Turbo.renderStreamMessage(html);
      }
    })
  }

  commitSuccess() {
    if(this.reloadAfterCompose) {
      if(this.frameToRefreshOnSuccess) {
        this.frameToRefreshOnSuccess.reload();
      } else {
        Turbo.visit(window.location);
      }
    }
    this.close();
  }

  switchToEdit() {
    document.querySelector("#compose-frame").src = `/channel-activities/${this.activityIdValue}/begin-compose`;
  }

  get reloadAfterCompose() {
    return document.querySelector("main").dataset.reloadAfterCompose == 'true';
  }

  get valid() {
    return this.sectionTargets.every(elem => elem.sectionController.valid);
  }

  get processing() {
    return this.sectionTargets.some(elem => { console.log(elem); return (elem.sectionController && elem.sectionController.processing) });
  }

  getPreview(assetId) {
    return this.previews[assetId];
  }

  getPreviewByUrl(url) {
    return this.previewsByUrl[url];
  }

  changeTab(event) {
    const rightIndex = this.tabRightTargets.indexOf(event.target);
    const leftIndex = this.tabLeftTargets.indexOf(event.target);
    
    if(rightIndex != -1) {
      this.tabRightTargets.forEach(tab => tab.classList.remove('tab-active'));
      event.target.classList.add('tab-active');

      const panelRightIndex = this.tabRightTargets.indexOf(event.target);
      this.panelRightTargets.forEach(panel => panel.classList.add('hidden'));
      this.panelRightTargets[panelRightIndex].classList.remove('hidden');
    }

    if(leftIndex != -1) {
      this.tabLeftTargets.forEach(tab => tab.classList.remove('tab-active'));
      event.target.classList.add('tab-active');

      const panelLeftIndex = this.tabLeftTargets.indexOf(event.target);
      this.panelLeftTargets.forEach(panel => panel.classList.add('hidden'));
      this.panelLeftTargets[panelLeftIndex].classList.remove('hidden');
    }
  }

  get imageEditorCheckbox() {
    return document.querySelector("#image-editor-modal");
  }

  editImage = async(assetId, assetName, url, crop) => {
    return new Promise(async(resolve) => {
      this.imageEditorCheckbox.checked = true;
      console.log("URL", url);
      ImageEditor.edit(url, crop)
                 .then((editor) => {
                   editor.on(UIEvent.CLOSE, async () => { 
                     editor.dispose();
                     this.imageEditorCheckbox.checked = false; 
                   });
                   editor.on(UIEvent.EXPORT, async (dataURL) => {
                    const blob = await (await fetch(dataURL)).blob(); 
                    const file = new File([blob], assetName, {type: "image/png"});
                    await this.requestAsset(file, (asset) => {
                      resolve(asset)
                    });
                    editor.dispose();
                    this.imageEditorCheckbox.checked = false;
                   });
                 });
    })
  }

  resizeImage = async(assetId, assetName, url, width) => {
    return new Promise(async(resolve) => {
      const file = await fetch(this.imgProxy(url, width)).then(res => res.blob());
      this.requestAsset(file, (asset) => { resolve(asset) }, 'image/png');
    });
  }

  async replaceImgWithAsset(evt) {
    const imgTag = evt.target;
    const url = imgTag.src;
    const file = await fetch(url).then(res => res.blob());

    this.requestAsset(file, (asset) => {
      imgTag.id = `asset_${asset.id}`;
      evt.params.extra.asset_id = asset.id;
      this.takeAction('links', 'attach_asset', evt.params.extra);
    }, 'image/png');
  }

  async fileStats(file) {
    const getSize = () => file.size;
    const readChunk = (chunkSize, offset) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
          if (event.target.error) {
            reject(event.target.error)
          }
          resolve(new Uint8Array(event.target.result))
        }
        reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize))
      })

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.mediainfo.analyzeData(getSize, readChunk).then((result) => {
          resolve(result);
        }, 1000);
      });
    });
  }

  async requestAsset(file, callback, forceType) {
    const stats = await this.fileStats(file);
    const type = forceType || file.type
    const name = file.name || ('file.' + type.split('/')[1])

    return this.post('/actions/refactor_create_presigned_asset', {
      content_type: forceType || file.type,
      size: file.size,
      secured: false,
      name: name.replace("+", ""),
      info: stats
    })
    .then(r => r.json())
    .then(json => {
      this.doUpload({ asset: json.result.asset, file: file, url: json.result.url, fields: json.result.fields })
      this.previews[json.result.asset.id] = file;
      this.previewsByUrl[json.result.asset.url] = file;
      callback(json.result.asset);
    })
  }

  emitProgress = (asset, progress) => {
    this.progressableTargets.forEach((elem) => {
      if(elem.dataset.assetId == asset.id) {
        elem.setAttribute('progress', progress*100);
      }
    })

    this.element.dispatchEvent(new CustomEvent('upload_progress', {
      detail: { asset, progress },
      bubbles: true
    }));
  }

  emitSuccess = (asset) => {
    this.progressableTargets.forEach((elem) => {
      if(elem.dataset.assetId == asset.id) {
        // Progress Complete event
        const event = new CustomEvent('progressCompleted', {
          detail: { assetId: asset.id },
          bubbles: true
        });
        elem.dispatchEvent(event);
        elem.setAttribute('progress', 0);
      }
    })

    this.element.dispatchEvent(new CustomEvent('upload_success', {
      detail: { asset },
      bubbles: true
    }));
  }

  emitFailure = (asset) => {
    // TODO
    this.element.dispatchEvent(new CustomEvent('upload_fail', {
      detail: { asset },
      bubbles: true
    }));
  }

  autoConvertAsset = async(assetId) => {
    this.element.dispatchEvent(new CustomEvent('auto_convert', {
      detail: { assetId },
      bubbles: true
    }));
    this.takeAction('media', 'auto_convert', {asset_id: assetId});
  }

  convertComposable = async(event) => {
    if(this.okToCommit()) {
      if(event.params.type != 'undefined') {
        this.element.classList.add('transition', 'duration-300', 'ease-in-out', 'opacity-10');
        setTimeout(() => {
          return this.post(`/composable/${this.idValue}/convert`, {
            type: event.params.type,
            content: this.state
          }).then(async(r) => {
            const html = await r.text();
            Turbo.renderStreamMessage(html);
          })
        });
      }
    } 
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //New Code
    return new Blob([ab], );
  }

  doUpload = (options) => {
    const { asset, file, url, fields } = options;
    const formData = new FormData();

    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key]);
    });

    // Actual file has to be appended last.
    formData.append("file", file);

    const xhr = new XMLHttpRequest();
    xhr.upload.onprogress = (ev) => { this.emitProgress(asset, ev.loaded / ev.total) }
    xhr.open("POST", url, true);
    xhr.send(formData);
    xhr.onload = (evt) => {
      if (xhr.status === 201) {
        this.emitSuccess(asset);
      } else {
        this.emitFailure(asset)
      }
    };
  }

  autoCaption(evt) {
    this.takeActionPause('media', 'auto_caption', { asset_id: evt.params.assetId });
  }

  onAssetUpload(asset, callback) {
    this.element.addEventListener('upload_success', (evt) => {
      if (evt.detail.asset.id === asset.id) {
        callback();
      }
    });
  }

  close() {
    setTimeout(() => {
      this.element.firstElementChild.remove();
      document.querySelector("#new-compose-modal").checked = false;
    }, 1000);
  }

  addText(evt) {
    //this.takeAction('social_message', 'add_text', {text: event.params.text});
    this.sectionTargets.forEach((section) => {
      console.log(section.sectionController);
      section['socialEditor'] ? section.socialEditor.addText(evt.params.text) : null;
    })
  }

  addMedia(assetId) {
    this.takeAction('media', 'attach_asset', {asset_id: assetId});
  }

  removeMedia(assetId) {
    this.takeAction('media', 'remove_asset', {asset_id: assetId});
  }

  remove(evt) {
    evt.currentTarget.remove();
  }
}
