import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['tab', 'underline']
  static classes = ['active']

  connect() {
    this.createActiveUnderline()
    this.applyActiveUnderline()

    // Don't animate until in proper spot
    this.addAmination();
  }

  tabTargetConnected(target) {
    target.addEventListener('click', this.changeTab.bind(this))
  }

  changeTab(event) {
    this.tabTargets.forEach(tab => {
      tab.classList.remove('active')
    })

    event.currentTarget.classList.add('active')
    this.applyActiveUnderline()
  }

  addAmination() {
    this.underlineTarget.classList.add('transition-all', 'duration-300', 'ease-in-out')
  }

  applyActiveUnderline() {
    const activeTab = this.tabTargets.find(tab => tab.classList.contains('active'))

    // Move underlineTarget under active tab
    const underline = this.underlineTarget
    underline.style.width = `${activeTab.offsetWidth}px`
    underline.style.left = `${activeTab.offsetLeft}px`
  }

  createActiveUnderline() {
    const underline = document.createElement('div')
    underline.classList.add(...this.activeClasses)
    underline.setAttribute('data-sa--tabs-target', 'underline')
    this.element.appendChild(underline)
  }
}
