import SectionController from './section_controller';
import '../../elements/progress_ring';

export default class extends SectionController {
  static targets = ['pickerFile', 'file', 'asset', 'assetArea', 'assetOption', 'gallery'];
  static values = {
    assets: Array
  }
  static classes = ['upload'];

  connect() {
    this.element.mediaController = this;
    super.connect();
  }

  disconnect() {
  }

  assetsValueChanged(newValue, oldValue) {
    this.dispatchOnManager('assets_changed', { assets: newValue });
  }

  assetSelected = (assetId) => {
    return this.assetsValue.includes(assetId);
  }

  assetTargetConnected = (target) => {
    this.assetsValue = this.assetsValue.concat([target.assetController.id]);
  }

  assetTargetDisconnected(target) {
    this.assetsValue = this.assetsValue.filter(assetId => assetId != target.assetController.id);
  }

  updateMedia = (event) => {
    const assetId = event.detail.assetId;
    const selected = event.detail.selected;

    if(selected) {
      this.composeManager.addMedia(assetId);
    } else {
      this.composeManager.removeMedia(assetId);
    }
    this.galleryController.showAssetDetail({params: {}});
  }

  assetOptionTargetConnected = (target) => {
    // If target asset id in assetsValue, mark as used
    if(this.assetsValue.includes(target.uploadAssetController.assetValue.id)) {
      target.uploadAssetController.checkedValue = true;
    }
  }

  assetOptionTargetDisconnected = (target) => {
  }

  assetSelected = (assetId) => {
    return this.assetTargets.find(assetTarget => assetTarget.assetController.id == assetId)
  }

  toggleMedia = (assetId) => {
    if(this.assetSelected(assetId)) {
      this.composeManager.removeMedia(assetId);
    } else {
      this.composeManager.addMedia(assetId);
    }
  }

  removeMedia = (event) => {
    this.composeManager.removeMedia(event.params.assetId);
  }

  addMedia = (event) => {
    this.composeManager.addMedia(event.params.assetId);
  }

  disappear = () => {
    this.element.classList.add('transition-all', 'opacity-0', 'scale-0');
  }

  uploadFile = (event) => {
    this.fileTarget.click();
  }

  openPicker = () => {
    //this.element.classList.add('show-media-picker');
    if(!this.element.classList.contains('show-media-picker')) {
      this.picker();
    }
  }

  picker = (event) => {
    // Custom event showPicker
    if(!this.element.classList.contains('show-media-picker')) {
      this.element.dispatchEvent(new CustomEvent('showPicker', { bubbles: true, detail: { } }));
    } else {
      this.element.dispatchEvent(new CustomEvent('hidePicker', { bubbles: true, detail: { } }));
    }
    this.element.classList.toggle('show-media-picker');
  }

  pickerUpload = (event) => {
    this.pickerFileTarget.click();
  }

  selectAsset = (event) => {
    if(this.galleryController.multiselectValue) {
      // Convert event to assetUpdate
      //this.mediaController.updateMedia(event);
      event.target.uploadAssetController.assetToggle();
    } else {
      this.openPicker();
      this.galleryController.showAssetDetail(event);
    }
  }

  imageDimensions = (url) => new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width
      })
    }
    img.src = url;
  })


  get state() {
    return {assets: this.assetTargets.map(assetTarget => assetTarget.assetController?.state())};
  }

  createPickerAssets = () => {
    Array.from(this.pickerFileTarget.files).forEach(async (file) => {
      await this.composeManager.requestAsset(file, asset => this.takeAction(
        this.key,
        'attach_asset', 
        { asset_id: asset.id },
      ))
    })

    // Clear so the same file can be added again
    this.pickerFileTarget.value = '';
  }

  attachAsset =  async(asset) => {
  }

  createAssets = () => {
    Array.from(this.fileTarget.files).forEach(async (file) => {
      await this.composeManager.requestAsset(file, asset => this.takeAction(
        this.key,
        'attach_asset', 
        { asset_id: asset.id },
      ))
    })

    // Clear so the same file can be added again
    this.fileTarget.value = '';
  }

  get galleryController() {
    return this.galleryTarget.galleryController;
  }
}
