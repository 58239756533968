import SaController from '../sa_controller';

export default class extends SaController {
  static targets = [ 'closeButton', 'doneButton' ]

  connect() {
    this.element.controller = this;
  }

  toggle(event) {
    if(event.params.mode === 'close') {
      if (this.closeButtonTarget.classList.contains('rotate-45')) {
        this.closeButtonTarget.classList.replace('rotate-45', 'hover:rotate-90');
        this.closeButtonTarget.classList.add('border-dashed');
      } else {
        this.closeButtonTarget.classList.replace('hover:rotate-90', 'rotate-45');
        this.closeButtonTarget.classList.remove('border-dashed');
      }
    } else if(event.params.mode === 'open') {
      return;      
    } else {
      if (this.doneButtonTarget.parentElement.classList.contains('hidden')) {
        this.doneButtonTarget.parentElement.classList.remove('hidden');
        this.closeButtonTarget.parentElement.classList.add('hidden');
      } else {
        this.doneButtonTarget.parentElement.classList.add('hidden');
        this.closeButtonTarget.parentElement.classList.remove('hidden');
      }
    }
  }

  reset() {
    this.doneButtonTarget.parentElement.classList.add('hidden');
    this.closeButtonTarget.parentElement.classList.remove('hidden');
    this.closeButtonTarget.classList.replace('rotate-45', 'hover:rotate-90');
    this.closeButtonTarget.classList.add('border-dashed');
  }
}
