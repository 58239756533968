import SectionController from './section_controller';

export default class extends SectionController {
  static targets = ['fileInput', 'media', 'assetDetail', 'assetDetailWrap', 'multiselect', 'mediascroll', 'assetOption', 'video'];
  static values = {
    selectedAssetId: String,
    assetPath: String,
    multiselect: Boolean,
    converting: Boolean
  }

  connect() {
    this.element.galleryController = this;
    this.setMultiselectText();
    this.listenOnManager('asset-updated', this.reloadSelectedAsset);
  }

  disconnect() {
    this.listenOffManager('asset-updated', this.reloadSelectedAsset);
  }

  reloadSelectedAsset = (event) => {
    if(event.detail.assetId == this.selectedAssetIdValue) {
      this.showAssetDetail({ detail: { assetId: this.selectedAssetIdValue } });
    }
  }

  toggleMultiselect() {
    this.multiselectValue = !this.multiselectValue;
    this.setMultiselectText();
  }

  setMultiselectText() {
    this.multiselectTarget.classList.toggle('multiselecting', !this.multiselectValue);
  }

  ensureOpened() {
    this.mediaController.openPicker();
  }

  editImage(assetId, name, url, network) {
    this.composeManager.editImage(assetId, name, url, network).then(asset => {
      this.createAsset(asset);

      if(this.mediaController.assetSelected(assetId)) {
        this.takeAction(this.mediaController.key, 'replace_asset', { asset_id: assetId, new_asset_id: asset.id });
      } else {
        //Should we attach on editing a non-attached asset?
        this.takeAction(this.mediaController.key, 'attach_asset', { asset_id: asset.id });
      }
      this.showAssetDetail({ detail: { assetId: asset.id } });
    });
  }

  fixError = (event) => {
    const assetId = event.params.assetId;
    const constraint = event.params.constraint;
    const dir = event.params.dir;
    const network = event.params.network;
    const name = event.params.name;
    const url = event.params.url;

    if(this.uploading) { 
      alert("Please wait for the image to finish uploading") 
      return
    }

    if(constraint == 'image_size') {
      this.composeManager.autoConvertAsset(assetId);
    } else if(constraint == 'format' || constraint == 'video_format') {
      this.composeManager.autoConvertAsset(assetId)
    } else if(constraint == 'video_length') {
      // TODO
    } else if(constraint == 'video_width') {
      this.composeManager.autoConvertAsset(assetId);
    } else if(constraint == 'image_aspect_ratio') {
      this.editImage(assetId, name, url, network);
    }
  }

  showAssetDetail = (event) => {
    this.selectedAssetIdValue = event.detail?.assetId || event.params?.assetId;
    const assetId = event.detail?.assetId || event.params?.assetId;
    this.assetDetailTarget.removeAttribute('complete');
    if(assetId) {
      this.assetDetailTarget.src = "/gallery/asset/" + assetId + "?composable_id=" + this.composeManager.idValue;
      this.assetDetailWrapTarget.classList.add('right-full');
      setTimeout(() => {
        this.assetDetailWrapTarget.classList.remove('right-full');
      }, 100);
    } else {
      this.assetDetailWrapTarget.classList.add('right-full');
      setTimeout(() => {
        this.assetDetailTarget.innerHTML = "";
      }, 100);
    }
  }

  editAsset = (event) => {
    const id = event.params.assetId;
    const name = event.params.name;
    const url = event.params.url;
    this.editImage(id, name, url);
  }

  assetPathValueChanged(value) {
    this.assetDetailTarget.src = value;
  }

  selectFiles = (event) => {
    this.fileInputTarget.click();
  }

  createAsset(asset) {
    asset.initializing = true;
    this.mediaTarget.insertAdjacentHTML('afterbegin', `
      <div data-controller="compose--option-asset" 
           data-compose--option-asset-asset-value='${JSON.stringify(asset)}' 
           data-compose--option-asset-selected-value="true"
           data-compose--option-asset-checkable-value="true"
           data-compose--media-target="assetOption" 
           data-action="assetUpdate->compose--media#updateMedia assetSelected->compose--media#selectAsset"></div>`)
  }

  createAssets = (event) => {
    Array.from(this.fileInputTarget.files).forEach(async (file) => {
      await this.composeManager.requestAsset(file, (asset) => {
        this.createAsset(asset);
        this.takeAction(this.mediaController.key, 'attach_asset', { asset_id: asset.id })
      })
    })

    // Clear so the same file can be added again
    this.fileInputTarget.value = '';
  }

  srcToFile(src, fileName, mimeType) {
    return (fetch(src)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], fileName, {type:mimeType});})
    );
  }

  videoSnapshot() {
    const canvas = document.createElement('canvas');
    const video = this.videoTarget;
    if (!video.videoWidth && !video.videoHeight) {
      throw new Error("Error snapshotting video");
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataURL = canvas.toDataURL();
    this.srcToFile(dataURL, `thumb-${Date.now()}.png`, 'image/png').then((file) => {
      this.composeManager.requestAsset(file, asset => { 
        this.takeAction(
          'media', 
          'really_attach_thumbnail', 
          {asset_id: this.selectedAssetIdValue, thumbnail_asset_id: asset.id}).then(() => {
            setTimeout(() => {
              this.showAssetDetail({ detail: { assetId: this.selectedAssetIdValue } });
            }, 100);
          });
      })
    });
  }

  get mediaController() {
    return this.element.closest('[data-controller=compose--media]').mediaController;
  }
}
