import SaController from './sa_controller';
import tippy from 'tippy.js';
//import "../stylesheets/popup.scss";

export default class extends SaController {
  static targets = ["trigger", "content"]
  static values = { 
    trigger: String,
    placement: { type: String, default: 'top-end' }
  }

  connect() {
    this.trigger = this.getTrigger();
    this.content = this.getContent();
    this.initPopup();
    this.content.style.display = "none";
  }

  initPopup = () => {
    if(this.triggerValue) {
      this.popup = tippy(this.trigger, {
        content: this.content.innerHTML,
        allowHTML: true,
        interactive: true,
        appendTo: () => document.body,
        trigger: this.triggerValue,
        placement: this.placementValue
      });
    } else {
      this.popup = tippy(this.trigger, {
        content: this.content.innerHTML,
        allowHTML: true,
        interactive: true,
        placement: this.placementValue
      });
    }
  }

  getContent() {
    if (this.hasContentTarget) {
      return this.contentTarget;
    } else {
      var content = document.createElement('div')
      content.innerHTML = this.element.dataset['content']
      return content
    }
  }

  getTrigger() {
    if (this.hasTriggerTarget) {
      return this.triggerTarget;
    } else {
      return this.element;
    }
  }
}
