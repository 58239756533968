import ComposeController from '../compose_controller';

export default class extends ComposeController {
  static values = {
    state: Object,
    converting: Boolean
  }

  connect() {
    this.element.assetController = this;
    this.element.mediaItemController = this;
    this.element.addEventListener('progressCompleted', this.progressCompleted);
  }

  progressCompleted = (event) => {
    this.refresh();
  }

  disappear() {
    this.element.classList.add('transition-all', 'duration-500', 'opacity-0', 'scale-0');
  }

  refresh() {
    this.takeAction('media', 'refresh_asset', {asset_id: this.id});
  }

  disconnect() {
    this.element.addEventListener('progressCompleted', this.progressCompleted);
  }

  autoConvert = (event) => {
    if(event.detail.assetId == this.id) {
      this.convertingValue = true;
    }
  }

  convertingValueChanged() {
    console.log('convertingValueChanged', this.convertingValue);
    if(this.convertingValue) {
      this.element.classList.add('animate-pulse');
    } else {
      this.element.classList.remove('animate-pulse');
    }
  }

  // For asset_alt_controller.js compat
  state() {
    return this.stateValue;
  }

  get id() {
    return this.stateValue.id;
  }
}
