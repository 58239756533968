import SaController from '../sa_controller';
import { Editor, Extension } from '@tiptap/core'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Color } from '@tiptap/extension-color'
//import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import TextAlign from '@tiptap/extension-text-align'
import HardBreak from '@tiptap/extension-hard-break'
import CharacterCount from '@tiptap/extension-character-count'
import Mention from '@tiptap/extension-mention'
import twitter from 'twitter-text'

import { debounce } from 'lodash';
import { createPicker } from 'picmo';

import tippy from 'tippy.js'
import SectionController from './section_controller';

// Let editor know when to find links
// Whitespace and paste
const LinkFinder = Extension.create({
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handlePaste: (view, event) => {
            view.link_update = true;
          }
        }})
    ];
  },
  addKeyboardShortcuts() {
    return {
      Enter: () => { 
        this.editor.view.link_update = true;
        return false;
      },
      Backspace: () => {
        this.editor.view.link_update = true;
        return false
      },
      Space: () => { 
        this.editor.view.link_update = true;
        return false;
      },
    };
  },
});

export default class extends SectionController {
  static targets = ['editor','mentionTemplate', 'content', 'count', 'emojiPicker', 'emojiPopup']
  static values = { 
    text: String,
    maxChars: Number,
    canMention: Boolean,
    twitter: Boolean,
    placeholder: String
  }

  get suggestion() {
    const controller = this;
    let currentQuery = "";

    return {
      items: ({ query }) => {
        currentQuery = query;
      },
      render: () => {
        let element;
        let popup;
        let command;

        return {
          onStart: props => {
            if(!controller.canMentionValue) return;

            element = controller.createMentionArea();
            if (!props.clientRect) {
              return
            }

            setTimeout(() => {
              element.mentionsController.setCommand(props.command);
            });

            popup = tippy('body', {
              getReferenceClientRect: props.clientRect,
              appendTo: () => document.body,
              content: element,
              showOnCreate: true,
              interactive: true,
              trigger: 'manual',
              placement: 'bottom-start',
            })
          },

          onUpdate(props) {
            if (!props.clientRect) {
              return
            }

            setTimeout(() => {
              element.mentionsController.setCommand(props.command);
            });

            popup[0].setProps({
              getReferenceClientRect: props.clientRect,
            })
          },

          onKeyDown(props) {
            if (props.event.key === 'Escape') {
              popup[0].hide()

              return true
            }

            //return component.ref?.onKeyDown(props)
            return element.mentionsController?.keyDown({query: currentQuery});
          },

          onExit() {
            popup[0].destroy()
          },
        }
      }
    };
  }

  connect() {
    super.connect();
    this.element.socialEditor = this;

    this.textValueChanged();
    this.editor = new Editor({
      element: this.editorTarget,
      onUpdate: this.editorUpdate,
      editorProps: {
        transformPastedHTML: (html) => {
          return html.replace(/<p><\/p>/g, "");
        }
      },
      extensions: [
        Document,
        Paragraph,
        Text,
        LinkFinder,
        Placeholder.configure({
          placeholder: this.placeholderValue || "What is the message..."
        }),
        HardBreak.extend({
          addKeyboardShortcuts () {
            return {
              Enter: () => this.editor.commands.setHardBreak()
            }
          }
        }),
        Mention.configure({
          HTMLAttributes: {
            class: 'px-1 rounded-sm bg-base-300',
          },
          renderLabel({ options, node }) {
            //return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
            return `${node.attrs.label ?? node.attrs.id}`
          },
          suggestion: this.suggestion
        })
      ],
      content: JSON.parse(this.contentTarget.innerHTML),
    })
    this.textValue = this.editor.getText({ blockSeparator: "\n" })
    this.setupEmojis();
  }

  setupEmojis() {
    if(this.hasEmojiPickerTarget) {
      this.emojiPickerElement =  document.createElement('div')
      this.emojiPicker = createPicker({ rootElement: this.emojiPickerElement });
      this.emojiPicker.addEventListener('emoji:select', event => {
        this.editor.commands.insertContent(event.emoji);
      });
      this.emojiPopup = tippy(this.emojiPickerTarget, {
        appendTo: () => document.body,
        content: this.emojiPickerElement,
        interactive: true,
        offset: [-10, -10],
        placement: 'top-start',
        trigger: 'click'
      });
    }
  }

  committing() {
    // Force finding links when committing
    this.editor.view.link_update = true;
  }

  clearText() {
    this.editor.commands.clearContent();
  }

  maxCharsValueChanged() {
    this.textValueChanged();
  }

  get hasTwitter() {
    return this.twitterValue;
  }

  twitterLength() {
    // Count asterisks twice
    return twitter.parseTweet(this.textValue.trimEnd()).weightedLength + this.textValue.trimEnd().split('*').length - 1;
  }

  textValueChanged() {
    if(!this.hasCountTarget) return;

    const length = this.hasTwitter ? this.twitterLength() : this.textValue.trimEnd().length;

    this.countTarget.innerHTML = `${length}/${this.maxCharsValue}`;

    this.countTarget.classList.add('hidden');
    this.countTarget.classList.remove('text-error', 'text-neutral');
    if(length > this.maxCharsValue) {
      this.countTarget.classList.remove('hidden');
      this.countTarget.classList.add('text-error')
    } else if(length > (this.maxCharsValue - 30)) {
      this.countTarget.classList.remove('hidden');
      this.countTarget.classList.add('text-neutral')
    } 
  }

  editorUpdate = ({editor, transaction}) => {
    this.textValue = this.editor.getText({ blockSeparator: "\n" })
    this.sendUpdate();
  }

  get state() {
    return {message: this.editor.getJSON(), findLinks: this.editor.view.link_update}
  }

  sendUpdate = debounce(() => {
    this.managerController.takeAction('social_message', 'update', this.state);
    this.editor.view.link_update = false;
  }, 600) 

  createMentionArea() {
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.mentionTemplateTarget.innerHTML, "text/html");
    return doc.body.firstElementChild;
  }

  disconnect() {
    this.editor.destroy()
  }

  addText(text) {
    console.log("TEXT", text);
    this.editor.commands.insertContent(text + "");
    // Add new line
    // this.editor.commands.setHardBreak();
  }
}

